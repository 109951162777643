import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ReadyToWork from '../components/readyToWork'
import ServicesSVG from '../components/backgroundSVGS/ServicesSVG'
// import { withPrefix } from 'gatsby'

const ProjectsPage = () => (
  <Layout>
    <Helmet
      title="Services | Element Studio - Cheltenham Web Design &amp; Development"
      meta={[
        {
          name: 'description',
          content:
            'Element Studio offer web design and development, as well as ongoing support and other services.',
        },
      ]}
    />
    <header className="hero">
      <div className="hero__inner">
        <h1 className="type-backdrop">
          <ServicesSVG />
        </h1>
        <div className="hero-content">
          <h2 className="type-title type-uppercase _has-subhead">What we do</h2>
          <span className="type-metadata">
            We have a very particular set of skills
          </span>
        </div>
      </div>
      <svg className="signpost-center" viewBox="0 0 8 74" width="8" height="74">
        <use xlinkHref="#icon-arrow-down" />
      </svg>
    </header>

    <section className="wrapper-chunky">
      <div className="container-wide">
        <div className="grid-x grid-margin-x grid-rhythm">
          <div className="cell small-12 medium-6 large-5">
            <h2 className="_is_visually-hidden">Our process</h2>
            <p className="type-body">
              <strong>
                From HTML emails, through to optimised landing pages, all the
                way up to bespoke, enterprise web applications and business
                tools we can help with almost any digital requirements.
              </strong>
            </p>
            <p className="type-body">
              For more information on our available services, please contact us
              via phone (
              <a href="tel:01242 651 111" className="type-textlink">
                01242 651 111
              </a>
              ) or email (
              <a
                href="mailto:howdy@builtbyelement.io"
                className="type-textlink"
              >
                howdy@builtbyelement.io
              </a>
              ) for more information.
            </p>
            <hr className="sep-short-red" />
          </div>
          <div className="cell small-12 medium-6 large-5 large-offset-2">
            <article className="feature_block feature_block-start">
              <div className="feature_block_header">
                <h3 className="type-label">1. Design</h3>
              </div>
              <div className="feature_block_content">
                <p className="type-body">
                  Our design process can mould to the requirements of all kinds
                  of projects, from early scamps and concepts down to perfecting
                  the fine details.
                </p>
              </div>
              <div className="feature_block_content cluster">
                <div>
                  <span className="cluster__item tag">Responsive Design</span>
                  <span className="cluster__item tag">UX Review</span>
                  <span className="cluster__item tag">Wireframes</span>
                  <span className="cluster__item tag">Interactive Prototypes</span>
                  <span className="cluster__item tag">Conceptual Design</span>
                </div>
              </div>
            </article>
          </div>
          <div className="cell small-12 medium-6 large-5">
            <article className="feature_block feature_block-left">
              <div className="feature_block_header">
                <h3 className="type-label">2. Development</h3>
              </div>
              <div className="feature_block_content">
                <p className="type-body">
                  We develop using open-source technologies and libraries such
                  as{' '}
                  <a
                    href="https://laravel.com/"
                    rel="nofollow"
                    className="type-textlink"
                  >
                    Laravel
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://reactjs.org/"
                    rel="nofollow"
                    className="type-textlink"
                  >
                    React
                  </a>
                  . We can build anything from HTML email templates up to
                  bespoke ecommerce solutions or complex business applications.
                </p>
              </div>
              <div className="feature_block_content cluster">
                <div>
                <span className="cluster__item tag">Laravel</span>
                <span className="cluster__item tag">Interactive Infographics</span>
                <span className="cluster__item tag">Mobile-First</span>
                <span className="cluster__item tag">Single-Page Apps</span>
                <span className="cluster__item tag">Wordpress</span>
                <span className="cluster__item tag">Front-end Development</span>
                <span className="cluster__item tag">React</span>
                <span className="cluster__item tag">Social Integration</span>
                <span className="cluster__item tag">Brochure Websites</span>
                <span className="cluster__item tag">Business Tools</span>
                </div>
              </div>
            </article>
          </div>
          <div className="cell small-12 medium-6 large-5 large-offset-2 feature_block_space" />
          <div className="cell small-12 medium-6 large-5 feature_block_space" />
          <div className="cell small-12 medium-6 large-5 large-offset-2">
            <article className="feature_block feature_block-right">
              <div className="feature_block_header">
                <h3 className="type-label">3. Ongoing</h3>
              </div>
              <div className="feature_block_content">
                <p className="type-body">
                  We like to work long-term with our clients to keep updating,
                  improving, and optimising their projects to maximise
                  performance. We can provide on-going development, email and
                  telephone support and all projects come with a 15 day bug
                  resolution guarantee.
                </p>
              </div>
              <div className="feature_block_content cluster">
                <div>
                  <span className="cluster__item tag">Retained Development</span>
                  <span className="cluster__item tag">Hosting</span>
                  <span className="cluster__item tag">Bug Tracking</span>
                  <span className="cluster__item tag">Retained Support</span>
                  <span className="cluster__item tag">User Testing</span>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <section className="wrapper-chunky wrapper-white">
      <div className="container-wide">
        <div className="grid-x grid-margin-x grid-rhythm">
          <div className="cell small-12 medium-6 large-5">
            <h2 className="_is_visually-hidden">Our network</h2>
            <p className="type-body">
              <strong>
                We will never look to provide a service that we are not able to
                confidently deliver on, we do however have a number of hand
                picked partner agencies and freelancers that we can offer as
                managed outsourced services.
              </strong>
            </p>

            <hr className="sep-short-red" />
          </div>
          <div className="cell small-12 medium-6 large-5 large-offset-2">
            <article className="feature_block feature_block-before">
              <div className="feature_block_header">
                <h3 className="type-label">4. Partner Services</h3>
              </div>
              <div className="feature_block_content">
                <p className="type-body">
                  It's all about who you know; If you need a splash of brand or
                  a smattering of animation, we have a couple of pals that we
                  can call on.
                </p>
              </div>
              <div className="feature_block_content cluster">
                <div>
                  <span className="cluster__item tag">PEN Testing</span>
                  <span className="cluster__item tag">Branding</span>
                  <span className="cluster__item tag">Animation</span>
                  <span className="cluster__item tag">Illustration</span>
                  <span className="cluster__item tag">Video Production</span>
                  <span className="cluster__item tag">PPC / SEO Campaigns</span>
                  <span className="cluster__item tag">Print Design</span>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <ReadyToWork />
  </Layout>
)

export default ProjectsPage
